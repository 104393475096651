var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", align: "middle", justify: "space-around" } },
        [
          _c(
            "a-dropdown",
            [
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c(
                    "a-menu-item",
                    {
                      key: "0",
                      on: {
                        click: function($event) {
                          return _vm.handleGoTo(_vm.pathToPages)
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("Page list")) + " ")
                      ])
                    ]
                  ),
                  _c(
                    "a-menu-item",
                    {
                      key: "1",
                      on: {
                        click: function($event) {
                          return _vm.handleGoTo(_vm.pathToEdit)
                        }
                      }
                    },
                    [_c("span", [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")])]
                  ),
                  _c(
                    "a-menu-item",
                    {
                      key: "2",
                      on: {
                        click: function($event) {
                          return _vm.params.context.componentParent.onOpenDuplicateProjectModalFromChild(
                            _vm.params.data
                          )
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("Duplicate")) + " ")
                      ])
                    ]
                  ),
                  !_vm.isPinned
                    ? _c(
                        "a-menu-item",
                        { key: "PinToTop", on: { click: _vm.handlePinning } },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("Pin to top")) + " ")
                          ])
                        ]
                      )
                    : _c(
                        "a-menu-item",
                        { key: "Unpin", on: { click: _vm.handlePinning } },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("Unpin")) + " ")
                          ])
                        ]
                      ),
                  _c(
                    "a-menu-item",
                    {
                      key: "3",
                      on: {
                        click: function($event) {
                          return _vm.handleGoTo(_vm.pathToArchivePages)
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.$t("Archived page list")) + " ")
                      ])
                    ]
                  ),
                  _c(
                    "a-menu-item",
                    {
                      key: "handleProjectExport",
                      on: {
                        click: function($event) {
                          return _vm.params.context.componentParent.handleProjectExportFromChild(
                            _vm.params.data
                          )
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("Export project")))])]
                  )
                ],
                1
              ),
              _c(
                "a-button",
                [
                  _vm._v(_vm._s(_vm.$t("Actions"))),
                  _c("a-icon", { attrs: { type: "down" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }