//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      loading: false
    };
  },
  computed: {
    projectId() {
      return this.params.data.id;
    },
    pathToEdit() {
      return `/projects/${this.projectId}`;
    },
    pathToPages() {
      return `/projects/${this.projectId}/pages`;
    },
    pathToArchivePages() {
      return `/projects/${this.projectId}/archived-pages`;
    },
    isPinned() {
      return this.params.data.pinned;
    }
  },
  methods: {
    handlePinning() {
      this.params.context.componentParent.handlePinToTopFromChild(this.params.data);
    },
    handleGoTo(path) {
      this.$router.push(path);
    }
  }
});
