var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _vm.isPinned
            ? _c(
                "a-tag",
                { attrs: { color: "#2e8bce" } },
                [_c("a-icon", { attrs: { type: "pushpin" } })],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c(
            "router-link",
            { staticClass: "mb-none text-ellipsis", attrs: { to: _vm.linkTo } },
            [_vm._v(" " + _vm._s(_vm.name) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }