//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  computed: {
    name() {
      return this.params.data.name;
    },
    linkTo() {
      return {
        name: 'page-list',
        params: {
          projectId: this.params.data.id
        }
      };
    },
    isPinned() {
      return this.params.data.pinned;
    }
  }
});
